// Generic styling elements for Flexboxgrid
// Import Sass Flex Mixin
@import './node_modules/sass-flex-mixin/flex';

.wrapper {
  max-width: $flexboxgrid-max-width;
  margin: 0 auto;
  box-sizing: border-box;
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-right: $outer-margin;
  padding-left: $outer-margin;
}

.row {
  @include flexbox();
  @include flex(0, 1, auto);
  @include flex-direction(row);
  @include flex-wrap(wrap);
  margin-right: $gutter-compensation;
  margin-left: $gutter-compensation;
  box-sizing: border-box;
}

.reverse.row {
  @include flex-direction(row-reverse);
}

.col.reverse {
  @include flex-direction(column-reverse);
}
