// Media query mixin
@mixin delta {
  @media(min-width: 75em) {
    @content;
  }
}

@mixin gamma {
  @media(min-width: 62em) and (max-width: 74.938em) {
    @content;
  }
}

@mixin beta {
  @media(min-width: 48em) and (max-width: 61.938em) {
    @content;
  }
}

@mixin alpha {
  @media(max-width: 47.938em) {
    @content;
  }
}

@mixin respond-from($from) {
  @media(min-width: $from) {
    @content;
  }
}

@mixin respond-to($to) {
  @media(max-width: $to) {
    @content;
  }
}
