// Column mixin
@mixin flexboxgrid-sass-column-common {
  box-sizing: border-box;

  // split @include flex(0, 0, auto) into individual props
  @include flex-grow(0);
  @include flex-shrink(0);

  // we leave @include flex-basis(auto) out of common because
  // in some spots we need it and some we dont
  // more why here: https://github.com/kristoferjoseph/flexboxgrid/issues/126

  padding-right: $half-gutter-width;
  padding-left: $half-gutter-width;
}
