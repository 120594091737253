.parallax {
  position: relative;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  list-style-type: none;

  @include respond-to(map-get($mq, alpha)) {
    height: calc(100vh - 80px);
  }
}

.parallax__layer {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.layer--1 {
  max-width: rem(250px);
  margin: 0 auto;

  @include respond-from(map-get($mq, alpha)) {
    max-width: rem(350px);
  }

  @include respond-from(map-get($mq, beta)) {
    max-width: rem(500px);
  }

  @include respond-from(map-get($mq, gamma)) {
    max-width: rem(650px);
  }

  @include respond-from(map-get($mq, delta)) {
    max-width: rem(750px);
  }
}

.parallax__title {
  margin: 0;
  padding: 10vh 0 0;
  color: map-get($color, alpha);
  font-family: map-get($font-family, alpha), map-get($font-family, alpha-fallback);
  font-size: rem(50px);
  font-weight: normal;
  line-height: .9;
  text-align: center;

  @include respond-from(map-get($mq, alpha)) {
    font-size: rem(70px);
  }

  @include respond-from(map-get($mq, beta)) {
    font-size: rem(100px);
  }

  @include respond-from(map-get($mq, gamma)) {
    font-size: rem(130px);
  }

  @include respond-from(map-get($mq, delta)) {
    padding-top: 15vh;
    font-size: rem(150px);
  }
}

.parallax__subtitle {
  margin: rem(20px) 0 0;
  padding: 0;
  color: map-get($color, beta);
  font-family: map-get($font-family, beta), map-get($font-family, beta-fallback);
  font-size: rem(15px);
  font-weight: normal;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;

  @include respond-from(map-get($mq, alpha)) {
    font-size: rem(20px);
  }

  @include respond-from(map-get($mq, beta)) {
    font-size: rem(25px);
  }

  @include respond-from(map-get($mq, gamma)) {
    font-size: rem(30px);
  }

  @include respond-from(map-get($mq, delta)) {
    margin-top: rem(40px);
    font-size: rem(35px);
  }
}

.layer--2 {
  z-index: 10;
  pointer-events: none;
}

.parallax__falcon {
  display: none;
  position: absolute;

  @include respond-from(map-get($mq, alpha)) {
    display: block;
    top: 30vw;
    left: 10vw;
    width: 40vw;
  }

  @include respond-from(map-get($mq, beta)) {
    top: 20vh;
    left: 5vw;
    width: 30vw;
  }
}

.layer--3 {
  z-index: 20;
}

.parallax__button {
  display: block;
  width: rem(200px);
  margin: 40vh auto 0;
  text-align: center;

  @include respond-from(map-get($mq, alpha)) {
    margin-top: 50vh;
  }

  @include respond-from(map-get($mq, gamma)) {
    margin-top: 55vh;
  }
}

.layer--4 {
  z-index: 30;
  pointer-events: none;
}

.parallax__stormtroopers {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0 auto;

  @include respond-from(map-get($mq, gamma)) {
    width: 75%;
    max-width: 1500px;
  }
}

.layer--5 {
  z-index: 40;
  pointer-events: none;
}

.parallax__win {
  display: none;
  position: absolute;
  right: 0;

  @include respond-from(map-get($mq, alpha)) {
    display: block;
    bottom: 0;
    width: rem(300px);
  }

  @include respond-from(map-get($mq, gamma)) {
    width: rem(400px);
  }

  @include respond-from(map-get($mq, delta)) {
    width: rem(500px);
  }
}
