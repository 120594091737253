// scss-lint:disable ImportantRule
// A cool and nice placeholder for clean lists
%clean-list {
  margin: 0;
  padding: 0;
  list-style-type: none !important;
}

.list-unstyled {
  @extend %clean-list;
}
