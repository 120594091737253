// Rem
@function rem($px, $base-val: map-get($base, font-size)) {
  @if not unitless($px) {
    $px: strip-units($px);
  }
  @if not unitless($base-val) {
    $base-val: strip-units($base-val);
  }

  @return ($px / $base-val) * 1rem;
}
