// scss-lint:disable ImportantRule
// Text alignment
.h-text-center {
  text-align: center !important;
}

.h-text-left {
  text-align: left !important;
}

.h-text-right {
  text-align: right !important;
}
