// Generic styling
*,
::before,
::after {
  box-sizing: border-box;
}

html,
body {
  min-height: 100%;
  margin: 0;
  padding: 0;
}

html {
  overflow: hidden;
}

body {
  @include font-smoothing(antialiased);
  background-image: url('../assets/img/bg.jpg');
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
}

a {
  display: inline;
  transition: '';
  color: '';
  text-decoration: none;
}

a:not([class]) {
  text-decoration: underline;
}

img {
  max-width: 100%;
}

button {
  outline: none;
  appearance: none;
}

p {
  margin-top: 0;
}
