// Flexboxgrid settings
// Editable
$grid-columns: 12 !default;
$gutter-width: 0 !default;
$outer-margin: rem(30px) !default;

$breakpoints:
  sm 48em 46rem,
  md 62em 61rem,
  lg 75em 80rem !default;

$flexboxgrid-max-width: rem(1200px) !default;

$gutter-compensation: $gutter-width * .5 * -1;
$half-gutter-width: $gutter-width * .5;

// Do not edit
$name: xs;
