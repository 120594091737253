// scss-lint:disable ImportantRule
// Hide elements helper
@include alpha {
  .hidden--alpha {
    display: none !important;
  }
}

@include beta {
  .hidden--beta {
    display: none !important;
  }
}

@include gamma {
  .hidden--gamma {
    display: none !important;
  }
}

@include delta {
  .hidden--delta {
    display: none !important;
  }
}
