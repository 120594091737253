// Container for grid
.container {
  max-width: rem(map-get($container-config, max-width));
  margin-right: map-get($container-config, margin-right);
  margin-left: map-get($container-config, margin-left);
  padding-right: rem(map-get($container-config, padding));
  padding-left: rem(map-get($container-config, padding));
}

.container-beta {
  max-width: rem(map-get($container-config, max-width-beta));
  margin-right: map-get($container-config, margin-right);
  margin-left: map-get($container-config, margin-left);
  padding-right: rem(map-get($container-config, padding));
  padding-left: rem(map-get($container-config, padding));
}

@include gamma {
  .container {
    width: rem(900px);
  }
}
