// Google Font
//@import url('https://fonts.googleapis.com/css?family=Share');

// Alpha font
@font-face {
  font-family: 'deathstar';
  font-style: normal;
  font-weight: normal;
  src: url($font-path-alpha-woff2) format('woff2'), url($font-path-alpha-woff) format('woff');
}

// Beta font
@font-face {
  font-family: 'henbc';
  font-style: normal;
  font-weight: normal;
  src: url($font-path-beta-woff2) format('woff2'), url($font-path-beta-woff) format('woff');
}
