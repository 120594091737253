.button {
  padding: rem(20px);
  transition: all .2s ease-in-out;
  border-radius: rem(50px);
  background: map-get($color, alpha);
  color: map-get($color, gamma);
  font-family: map-get($font-family, beta), map-get($font-family, beta-fallback);
  font-size: rem(20px);
  text-transform: uppercase;
  box-shadow: 0 0 rem(50px) rgba(map-get($color, alpha), .8);

  &:hover {
    transform: scale(1.1);
  }
}
