// Flexboxgrid columns
.column-#{$name} {
  @include flexboxgrid-sass-column-common;
  @include flex-basis(auto);
}

@for $i from 1 through $grid-columns {
  .column-#{$name}-#{$i} {
    @include flexboxgrid-sass-column-common;
    @include flex-basis(100% / $grid-columns * $i);
    max-width: 100% / $grid-columns * $i;
  }
}

@for $i from 1 through $grid-columns {
  .column-#{$name}-offset-#{$i} {
    @include flexboxgrid-sass-column-common;
    margin-left: 100% / $grid-columns * $i;
  }
}

.column-#{$name} {
  @include flex-grow(1);
  @include flex-basis(0);
  max-width: 100%;
}
