$base: (
  font-size: 16px
);

// Container
$container-config: (
  min-width: 260px,
  max-width: 1200px,
  max-width-beta: 800px,
  margin-top: 0,
  margin-bottom: 0,
  margin-left: auto,
  margin-right: auto,
  padding: 30px
);

// Margin
$margin: (
  alpha: 10px,
  beta: 20px,
  gamma: 30px,
  delta: 40px,
  epsilon: 50px,
  zeta: 80px,
  eta: 100px
);

// Padding
$padding: (
  alpha: 10px,
  beta: 20px,
  gamma: 30px,
  delta: 40px,
  epsilon: 50px,
  zeta: 80px,
  eta: 100px
);

// Media Queries
$mq: (
  alpha: 48em,
  beta: 62em,
  gamma: 75em,
  delta: 100em
);

$color: (
  alpha: #f5e100,
  beta: white,
  gamma: black
);

$font-family: (
  alpha: deathstar,
  alpha-fallback: sans-serif,
  beta: henbc,
  beta-fallback: sans-serif
);

$font-path-alpha-woff: '../assets/fonts/death_star-webfont.woff';
$font-path-alpha-woff2: '../assets/fonts/death_star-webfont.woff2';

$font-path-beta-woff: '../assets/fonts/HENBC.woff';
$font-path-beta-woff2: '../assets/fonts/HENBC.woff2';
