// Flexboxgrid breakpoint
@each $breakpoint in $breakpoints {
  $name: nth($breakpoint, 1);
  $size: nth($breakpoint, 2);
  $container: nth($breakpoint, 3);

  @media only screen and (min-width: $size) {
    .container {
      width: $container;
    }

    .column-#{$name} {
      @include flexboxgrid-sass-column-common;
      @include flex-basis(auto);
    }

    @for $i from 1 through $grid-columns {
      .column-#{$name}-#{$i} {
        @include flexboxgrid-sass-column-common;
        @include flex-basis(100% / $grid-columns * $i);
        max-width: 100% / $grid-columns * $i;
      }
    }

    @for $i from 1 through $grid-columns {
      .column-#{$name}-offset-#{$i} {
        @include flexboxgrid-sass-column-common;
        margin-left: 100% / $grid-columns * $i;
      }
    }

    .column-#{$name} {
      @include flex-grow(1);
      @include flex-basis(0);
      max-width: 100%;
    }

    .start-#{$name} {
      @include justify-content(flex-start);
      text-align: start;
    }

    .center-#{$name} {
      @include justify-content(center);
      text-align: center;
    }

    .end-#{$name} {
      @include justify-content(flex-end);
      text-align: end;
    }

    .top-#{$name} {
      @include align-items(flex-start);
    }

    .middle-#{$name} {
      @include align-items(center);
    }

    .bottom-#{$name} {
      @include align-items(flex-end);
    }

    .around-#{$name} {
      @include justify-content(space-around);
    }

    .between-#{$name} {
      @include justify-content(space-between);
    }

    .first-#{$name} {
      order: -1;
    }

    .last-#{$name} {
      order: 1;
    }
  }
}
