// scss-lint:disable ImportantRule
// Margin helper classes
.margin {
  @each $key, $value in $margin {
    &-#{$key} {
      margin: rem(map-get($margin, $key)) !important;

      &-vertical {
        margin-top: rem(map-get($margin, $key)) !important;
        margin-bottom: rem(map-get($margin, $key)) !important;
      }

      &-horizontal {
        margin-right: rem(map-get($margin, $key)) !important;
        margin-left: rem(map-get($margin, $key)) !important;
      }

      &-right { margin-right: rem(map-get($margin, $key)) !important; }
      &-left { margin-left: rem(map-get($margin, $key)) !important; }
      &-top { margin-top: rem(map-get($margin, $key)) !important; }
      &-bottom { margin-bottom: rem(map-get($margin, $key)) !important; }
    }
  }
}

.no-margin { margin: 0 !important; }
.no-bottom-margin { margin-bottom: 0 !important; }
.no-top-margin { margin-top: 0 !important; }
.no-left-margin { margin-left: 0 !important; }
.no-right-margin { margin-right: 0 !important; }

.no-vertical-margin {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.no-horizontal-margin {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
