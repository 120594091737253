// Footer
.footer {
  position: absolute;
  right: rem(50px);
  bottom: rem(170px);
  left: rem(50px);
  transform: scale(.8);
  text-align: center;
  z-index: 50;

  @include respond-from(map-get($mq, alpha)) {
    bottom: rem(50px);
    transform: scale(1);
    text-align: left;
  }
}

.footer__anchor {
  color: map-get($color, beta);
  font-family: map-get($font-family, beta), map-get($font-family, beta-fallback);
  font-size: rem(20px);
  text-transform: uppercase;
}

.footer__logo {
  display: inline-block;
  height: rem(21px);
  margin-left: rem(10px);
}
